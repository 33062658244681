<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-card>
        <v-card-title class="font-weight-bold  text-break">
          Monto certificado total
        </v-card-title>
        <v-card-text>
             <span class="font-weight-bold text-h6 ">
              Monto proyectado: {{
                 Intl.NumberFormat("en-US", {
                   style: "currency",
                   currency: "USD",
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2,
                 }).format(get_global_subasta?.monto_proyectado_necesidades)
               }}
              <br>
              Ahorro total: {{
                 Intl.NumberFormat("en-US", {
                   style: "currency",
                   currency: "USD",
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2,
                 }).format(get_global_subasta?.monto_proyectado_necesidades - get_global_subasta?.monto_ganado)
               }}
             </span>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="4">
      <v-card>
        <v-card-title class="font-weight-bold  text-break">
          Monto proyectado por lotes total
        </v-card-title>
        <v-card-text>
             <span class="font-weight-bold text-h6 ">
                Monto proyectado: {{
                 Intl.NumberFormat("en-US", {
                   style: "currency",
                   currency: "USD",
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2,
                 }).format(get_global_subasta?.monto_proyectado_especificas)
               }}
              <br/>
              Ahorro total: {{
                 Intl.NumberFormat("en-US", {
                   style: "currency",
                   currency: "USD",
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2,
                 }).format(get_global_subasta?.monto_proyectado_especificas - get_global_subasta?.monto_ganado)
               }}
             </span>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="4">
      <v-card>
        <v-card-title class="font-weight-bold  text-break">
          Total ejecutado
        </v-card-title>
        <v-card-text>
          <br>

             <span class="font-weight-bold text-h6 ">
              Total: {{
                 Intl.NumberFormat("en-US", {
                   style: "currency",
                   currency: "USD",
                   minimumFractionDigits: 2,
                   maximumFractionDigits: 2,
                 }).format(get_global_subasta?.monto_ganado)
               }}
             </span>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'CardTotales',
  data: () => ({
    totales: null
  }),
  methods: {
    ...mapActions('subastaInversaReporteria', ['obtenerTotales']),
  },
  computed:{
    ...mapState('subastaInversaReporteria', ['get_global_subasta'])
  },
  async created() {
    this.totales = await this.obtenerTotales()
  }
}
</script>
