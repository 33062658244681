<template>
  <v-container>
    <v-card>
      <div class="d-flex align-center px-4 py-4" style="gap: 16px;">
        <NavButton />
        <h2 class="text-h5 text-uppercase font-weight-bold">Reportería de subasta inversa</h2>
      </div>
      <v-divider />
      <div class="px-4 py-4">
        <Estadisticas />
        <Filtros class="mt-12" />
        <Listado class="mt-4" />
      </div>
    </v-card>
  </v-container>
</template>
<script>
import Listado from './components/listado.vue'
import Filtros from './components/filtros.vue'
import CardProveedor from './components/cardProveedorSeleccionado.vue'
import CardTotales from "./components/cardTotales.vue";
import { NavButton } from '@/components/utils';
import Estadisticas from './components/Estadisticas.vue';
import { mapState } from 'vuex';


export default {
  name: "ReporteriaDirectorSubastaInversaMain",
  components: { Listado, Filtros, CardProveedor, CardTotales, NavButton, Estadisticas },
  data: () => ({
    tab: null
  }),
  computed: {
    ...mapState('subastaInversaReporteria', ['filtros']),
  },
}
</script>
