<template>
  <Fragment>
    <DataTableComponent
        dense
        :items="listado"
        :headers="headers"
        :show_loading="cargando"
        :total_registros="paginacion.total ?? 0"
        v-models:pagina="paginacion.page"
        v-models:select="paginacion.perPage"
        @paginar="manejarPaginacion"
    >
      <template v-slot:[`item.inicio_subasta`]="{ item }">
        {{ moment(item.inicio_subasta).format('DD/MM/YYYY HH:mm') }}
      </template>
      <template v-slot:[`item.fin_subasta`]="{ item }">
        {{ moment(item.fecha_programada_finalizacion).format('DD/MM/YYYY HH:mm') }}
      </template>
      <template v-slot:[`item.estado_lote`]="{ item }">
        <v-chip :color="calcularColor(item.estado_lote)" class="white--text" label>
          {{ item.estado_lote }}
        </v-chip>
      </template>
      <template v-slot:[`item.monto`]="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.monto ?? 0)
        }}
      </template>
      <template v-slot:item.monto_inicial="{ item }">
        {{
          Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item.monto_inicial ?? 0)
        }}
      </template>
  
      <template v-slot:[`item.nombre_proceso`]="{ item }">
        <span>
          {{ item.codigo_proceso }} -
          {{ item.nombre_proceso }}
        </span>
      </template>
      
      <template v-slot:item.detalles="{ item }">
        <div class="d-flex align-center justify-center">
          <v-btn icon color="secondary" @click.stop="abrirModalDetallesSubasta(item)">
            <v-icon color="secondary">mdi-eye</v-icon>
          </v-btn>
        </div>
      </template>
    </DataTableComponent>
    <ModalDetalleSubasta :is-open.sync="modalDetallesSubastaAbierto" :subasta="subastaSeleccionada" />
  </Fragment>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import ModalDetalleSubasta from './ModalDetalleSubasta.vue';
import { mapActions, mapState } from "vuex";
import { Fragment } from "vue-fragment";

export default {
  name: "ReporteriaDirectorSubastaInversaListado",
  data: () => ({
    colores: ['#00B6F5', '#0993cd', '#1270a6', '#1b4d7f', '#252B58'],
    subastaSeleccionada: null,
    modalDetallesSubastaAbierto: false,
  }),
  components: { DataTableComponent, ModalDetalleSubasta, Fragment },
  computed: {
    ...mapState('subastaInversaReporteria', ['listado', 'cargando', 'paginacion', 'filtros']),
    headers() {
      return [
        {
          text: 'Institucion',
          align: 'start',
          sortable: false,
          value: 'institucion',
          width: '20%'
        },
        {
          text: 'Proceso de compra',
          align: 'start',
          sortable: false,
          value: 'nombre_proceso',
          width: '35%'
        },
        {
          text: 'Lote',
          align: 'start',
          sortable: false,
          value: 'nombre_lote',
          width: '15%'
        },
        {
          text: 'Tipo de subasta',
          align: 'start',
          sortable: false,
          value: 'tipo_subasta',
          width: '10%'
        },
        {
          text: 'Estado del lote',
          align: 'start',
          sortable: false,
          value: 'estado_lote',
          width: '5%'
        },
        {
          text: 'Inicio de subasta',
          align: 'start',
          sortable: false,
          value: 'inicio_subasta',
          width: '5%'
        },
        {
          text: 'Fecha programada finalización',
          align: 'start',
          sortable: false,
          value: 'fin_subasta',
          width: '7%'
        },
        {
          text: 'Proveedor ganador',
          align: 'start',
          sortable: true,
          value: 'ganador',
          width: '15%'
        },
        {
          text: 'Monto ganador',
          align: 'start',
          sortable: false,
          value: 'monto',
          width: '5%'
        },
        {
          text: 'Monto inicial',
          align: 'start',
          sortable: false,
          value: 'monto_inicial',
          width: '5%'
        },
        {
          text: 'Detalles',
          align: 'start',
          sortable: false,
          value: 'detalles',
          width: '5%'
        },
      ];
    },
  },
  methods: {
    ...mapActions('subastaInversaReporteria', ['setPagination', 'obtenerListado']),
    async manejarPaginacion(paginacion) {
      await this.setPagination(paginacion)
      await this.obtenerListado({
        ...this.filtros,
        ...this.paginacion
      });
    },
    calcularColor(color) {
      switch (color) {
        case 'Desierto':
          return '#00B6F5';
        case 'Adjudicado':
          return '#0993cd';
        case 'Pendiente':
          return '#1270a6';
        default:
          return '#1b4d7f';
      }
    },
    abrirModalDetallesSubasta(subasta) {
      this.subastaSeleccionada = {...subasta};
      this.modalDetallesSubastaAbierto = true;
    },
  },
  async created() {
    await this.obtenerListado({
      ...this.filtros,
      ...this.paginacion
    });
  }
}
</script>
