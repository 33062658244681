<template>
  <v-row justify="end" align="center" dense>
    <v-col cols="12" md="4" lg="3">
      <v-autocomplete
          v-model="filtros.id_institucion"
          :items="instituciones"
          :loading="cargando"
          label="Institución"
          placeholder="Seleccione una institución"
          item-value="id"
          item-text="nombre"
          outlined
          clearable
          hide-details
      />
    </v-col>
    <v-col cols="12" md="4" lg="3">
      <v-autocomplete
          v-model="filtros.proveedor"
          :items="proveedores"
          :loading="cargando"
          label="Proveedor"
          placeholder="Seleccione un proveedor"
          item-value="id"
          item-text="nombre"
          outlined
          clearable
          hide-details
      />
    </v-col>
    <v-col cols="12" md="4" lg="3">
      <div class="d-flex align-center flex-wrap justify-center" style="gap: 8px;">
        <v-btn
            @click="limpiarFiltros"
            x-large
            :disabled="cargando"
            color="secondary"
            class="flex-grow-1 flex-shrink-1"
        >
          Limpiar
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import {mapActions, mapState} from 'vuex';

const filtrosIniciales = {
  id_institucion: null,
  proveedor: null,
};

export default {
  name: "ReporteriaSubastaInversaFiltros",
  data: () => ({
    filtros_locales: {...filtrosIniciales},
  }),
  components: {},
  methods: {
    ...mapActions(
        'subastaInversaReporteria', [
          'obtenerInstituciones',
          'obtenerListado',
          'setFiltros',
          'setPagination',
          'obtenerProveedores',
          'obtenerTotales'
        ]),
    async aplicarFiltro() {
      await this.obtenerListado({...this.filtros, ...this.paginacion});
      await this.obtenerTotales(
          {
            ...this.filtros
          }
      )
    },
    async limpiarFiltros() {
      await this.setFiltros({...filtrosIniciales});
      await this.setPagination({pagina: 1, cantidad_por_pagina: 10})
      this.filtros_locales = {...filtrosIniciales};
      await this.aplicarFiltro();
    },
  },
  watch: {
    'filtros': {
      handler: function async () {
        this.setPagination({
          pagina: 1,
          cantidad_por_pagina: 10,
        })
        this.aplicarFiltro({
          ...this.filtros
        });
      },
      deep: true
    },
  },
  computed: {
    ...mapState(
        'subastaInversaReporteria',
        [
          'instituciones',
          'filtrosInstitucion',
          'filtros',
          'paginacion',
          'cargando',
          'proveedores'
        ],
    ),
  },
  async created() {
    await this.obtenerInstituciones();
    await this.obtenerProveedores();
  },
}
</script>
