<template>
  <v-card :loading="cargando">
    <v-card-title>
      Total adjudicado al proveedor seleccionado:
    </v-card-title>
    <v-card-text>
      {{ Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(listado.reduce((acc, item) => acc + item.monto, 0) ?? 0) }}
    </v-card-text>
  </v-card>
</template>
<script>
import {mapState} from 'vuex';

export default {
  name: 'CardProveedorSeleccionado',
  computed: {
    ...mapState(
        'subastaInversaReporteria', [
          'instituciones',
          'filtrosInstitucion',
          'filtros',
          'paginacion',
          'cargando',
          'proveedores',
          'listado',
        ]),
  },
}
</script>
